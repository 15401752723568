import React from "react";
import { createRoot } from "react-dom/client";
import Main from "./Main";
import "./index.css";

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/service-worker.js', { scope: '/' })
      .then(function(registration) {
        console.log('Service Worker registered:', registration);
      })
      .catch(function(error) {
        console.log('Service Worker registration failed:', error);
      });
  });
}

createRoot(document.getElementById("root")).render(<Main />);



