import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./HamburgerMenu.css";

function HamburgerMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`hamburger-menu ${isOpen ? "open" : ""}`}
      onClick={toggleMenu}
    >
      <div className="line"></div>
      <div className="line"></div>
      <div className="line"></div>
      <ul className={`menu ${isOpen ? "open" : ""}`} onClick={toggleMenu}>
<li>
  <NavLink exact="true" to="/" activeclassname="active">
    HOME
  </NavLink>
</li>

        
        <li>
          <NavLink to="/Quiz" activeclassname="active">
            QUIZ
          </NavLink>
        </li>
        <li>
          <NavLink to="/HybridView" activeclassname="active">
            G.O.A.T.
          </NavLink>

        </li>
                <li>
          <NavLink to="/Ped" activeclassname="active">
          PEDIGREE
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default HamburgerMenu;

