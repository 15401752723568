import React, { useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import PedUsersForm from './PedUsersForm';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const AuthComponent = () => {
  const [loading, setLoading] = useState(false);
  const [signupEmail, setSignupEmail] = useState('');
  const [signupPassword, setSignupPassword] = useState('');
  const [signinEmail, setSigninEmail] = useState('');
  const [signinPassword, setSigninPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const signUp = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { user, error } = await supabase.auth.signUp({
        email: signupEmail,
        password: signupPassword,
      });
      if (error) {
        console.error('Signup error:', error);
        throw error;
      }
      console.log('User signed up:', user);
      setSuccessMessage('Sign up successful!');
    } catch (error) {
      console.error('Signup error:', error);
      alert(error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  };

  const signIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { user, error } = await supabase.auth.signInWithPassword({
        email: signinEmail,
        password: signinPassword,
      });
      if (error) throw error;
      console.log('User signed in:', user);
      setSuccessMessage('Sign in successful!');
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Sign-in error:', error);
      alert(error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
  <div>
    {!isAuthenticated && (
      <div>
        <p>If you would like to contribute ancestor pedigrees to the database, please first sign up for an account or sign in if you already have an account.</p>
      </div>
    )}
      <div style={{ display: 'flex' }}>
        {!isAuthenticated ? (
          <>
            <div style={{ marginRight: '50px' }}>
              <p style={{ color: 'red' }}>Sign up for a new account:</p>
              <form onSubmit={signUp}>
                <input
                  value={signupEmail}
                  onChange={(e) => setSignupEmail(e.target.value)}
                  placeholder="Sign Up Email"
                />
                <input
                  value={signupPassword}
                  onChange={(e) => setSignupPassword(e.target.value)}
                  placeholder="Sign Up Password"
                  type="password"
                />
                <button type="submit" disabled={loading}>
                  Sign Up
                </button>
              </form>
            </div>

            <div>
              <p style={{ color: 'green' }}>Sign into your existing account:</p>
              <form onSubmit={signIn}>
                <input
                  value={signinEmail}
                  onChange={(e) => setSigninEmail(e.target.value)}
                  placeholder="Sign In Email"
                />
                <input
                  value={signinPassword}
                  onChange={(e) => setSigninPassword(e.target.value)}
                  placeholder="Sign In Password"
                  type="password"
                />
                <button type="submit" disabled={loading}>
                  Sign In
                </button>
              </form>
            </div>

            {successMessage && <p>{successMessage}</p>}
          </>
        ) : (
          <PedUsersForm />
        )}
      </div>
    </div>
  );
};

export default AuthComponent;
