import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom'; // Add Link to your imports
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);


const Siblings = () => {
  const [siblings, setSiblings] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchName = searchParams.get('search_name');

  useEffect(() => {
    const fetchSiblings = async () => {
      const { data, error } = await supabase.rpc('find_siblings', { search_name: searchName });

      if (error) {
        console.error('Error calling find_siblings:', error);
        return;
      }

      setSiblings(data);
      setLoading(false);
    };

    fetchSiblings();
  }, [searchName]);

  if (loading) {
    return <h3>Loading...</h3>;
  }

  return (
    <>
    <h3>Siblings of {searchName}</h3>
    <div style={{ display: 'flex', justifyContent: 'space-around', margin: '10px 0' }}>
    <a 
      href={`/Progeny?search_name=${searchName}`} 
      style={{ fontSize: '12px', padding: '10px' }}>
      Progeny of {searchName}
    </a>   
    <a 
      href={`/Ped?search_name=${searchName}`} 
      style={{ fontSize: '12px', padding: '10px' }}>
      Pedigree of {searchName}
    </a>
  </div>
    {siblings.length === 0 ?
      <p>No siblings found</p> :
      <table className="results-table">
        <thead>
          <tr>
            <th>Goat Name</th>
            <th>Dam Name</th>
            <th>Sire Name</th>
            <th>Sibling type</th>
          </tr>
        </thead>
        <tbody>
          {siblings.map((sibling) => (
            <tr key={sibling.PedigreeId}>
              <td><Link to={`/Ped?search_name=${sibling.animal_name}`}>{sibling.animal_name}</Link></td> {/* Wrap the animal name in a Link component */}
              <td>{sibling.dam_name}</td>
              <td>{sibling.sire_name}</td>
              <td>{sibling.sibling_type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    }
    </>
  );
};

export default Siblings;
