import React, { Component } from "react";
import { NavLink, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Search from "./Search";
import Goat from "./Goat";
import Contact from "./Contact";
import { TopPerformersTable } from './AllTime';
import HamburgerMenu from './HamburgerMenu';
import HybridView from './HybridView';
import Protein from "./Protein";
import OldestGoat from "./OldestGoat";
import './index.css';
import Quiz from './Quiz';
import Ped from "./Ped";
import Siblings from "./Siblings";
import Progeny from "./Progeny";
import AuthComponent from "./AuthComponent";
import PedUsersForm from "./PedUsersForm";


class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen
    });
  }

  render() {
    return (
      <Router>
        <div>
          <HamburgerMenu onClick={this.toggleMenu} />
             <ul className={`header ${this.state.menuOpen ? 'show' : ''}`}>
            <li><NavLink exact="true" to="/" activeclassname="active">Home</NavLink></li>
            <li><NavLink to="/HybridView" activeclassname="active">G.O.A.T. </NavLink></li>
            <li><NavLink to="/Quiz" activeclassname="active">Quiz</NavLink></li>
            <li><NavLink to="/Ped" activeclassname="active">PedSearch</NavLink></li>
             <li><NavLink to="/AuthComponent" activeclassname="active">Add-a-Goat</NavLink></li>
          </ul>
          <div className="content">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/Goat" element={<Goat />} />
              <Route path="/AllTime" element={<TopPerformersTable />} />
              <Route path="/Search" element={<Search />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="/HybridView" element={<HybridView />} />
              <Route path="/Protein" element={<Protein />} />
              <Route path="/OldestGoat" element={<OldestGoat />} />
              <Route path="/Quiz" element={<Quiz />} />
              <Route path="/Ped" element={<Ped />} />
              <Route path="/Siblings" element= {<Siblings />} />
              <Route path="/Progeny" element= {<Progeny />} />
              <Route path="/AuthComponent" element= {<AuthComponent />} />
              <Route path="/PedUsersForm" element= {<PedUsersForm />} />
              </Routes>
          </div>
        </div>
      </Router>
    );
  }
}

export default Main;









