/* eslint-disable react/jsx-key */
import { useState } from "react";
import './quiz.css';


const QUIZ_QUESTIONS = [
  {
    Question: "Which breeder has the most appearances in the ADGA Top Ten list?",
    Answers: [
      { Answer: "Sharon Peck", isCorrect: false },
      { Answer: "David Funk", isCorrect: true },
      { Answer: "Cheryle Moore-Smith", isCorrect: false },
      { Answer: "Keith Harrell", isCorrect: false },
    ],
  },
  {
    Question: "Which goat has the most appearances in the ADGA Top Ten?",
    Answers: [
      { Answer: "Chenango-Hills I P Goddess", isCorrect: true },
      { Answer: "Ironwood SJ Minnie", isCorrect: false },
      { Answer: "Little Hoofprints Kona", isCorrect: false },
      { Answer: "Cedar View Isabella", isCorrect: false },
    ],
  },
  {
    Question: "Who was the oldest doe in the Top Ten and also a National ADGA Champion?",
    Answers: [
      { Answer: "Wyojem Wendy Kiki", isCorrect: true },
      { Answer: "Urban Acres Gypsy Vanner", isCorrect: false },
      { Answer: "Promisedland Pal Paloma", isCorrect: false },
      { Answer: "Sugar Moon Zip Me Up", isCorrect: false },
    ],
  },
  {
    Question: "Name the dam on the current (2021) Top Ten list along wih TWO of her daughters? And don't cheat, either.",
    Answers: [
      { Answer: "Madeline, Muriel, and Sassfire", isCorrect: false },
      { Answer: "Phoebe, Rosalia, and Medeira", isCorrect: false },
      { Answer: "Inky, Dinky, and Spots", isCorrect: false },
      { Answer: "Diane Legacy, Senaca, Princess Diana", isCorrect: true },
    ],
  },
  {
    Question: "How many Nigerian does have milked over 2,000 pounds in a single lactation",
    Answers: [
      { Answer: "1", isCorrect: false },
      { Answer: "3", isCorrect: true },
      { Answer: "2", isCorrect: false },
      { Answer: "7", isCorrect: false },
    ],
  },
  {
    Question: "First Nigerian doe to lead the Top Ten AND win ADGA National Champion",
    Answers: [
      { Answer: "Jobi Filene", isCorrect: false },      
      { Answer: "Gryphon St Darlin", isCorrect: false },
      { Answer: "Rosasharn P Haiku", isCorrect: true },
      { Answer: "Kaapio Acres HH Redemption", isCorrect: false },
    ],
  },

];

const Quiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const handleAnswerResponse = (isCorrect) => {
    if (isCorrect) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < QUIZ_QUESTIONS.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };
  const resetQuiz = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowScore(false);
  };

  return (
    <main>
      <section>
        <div className="quiz-container">
          <h3>Test Your Milky Knowledge</h3>
          {showScore ? (
            <div>
              <p>
                You have scored {score} out of {QUIZ_QUESTIONS.length}
              </p>
              <button type="submit" onClick={resetQuiz}>
                Play Again!!
              </button>
            </div>
          ) : (
            <>
              <h2>{QUIZ_QUESTIONS[currentQuestion].Question}</h2>
              <div className="quiz-answer-row">
                {QUIZ_QUESTIONS[currentQuestion].Answers.map((answer, answerIndex) => (
                  <button
                    className="quiz-answer"
                    onClick={() => handleAnswerResponse(answer.isCorrect)}
                    key={`${currentQuestion}-${answerIndex}`}
                  >
                    {answer.Answer}
                  </button>
                ))}
              </div>
              <div>
                <p id="p-number">
                  {currentQuestion + 1}
                  <span>/{QUIZ_QUESTIONS.length}</span>
                </p>
              </div>
            </>
          )}
        </div>
      </section>
    </main>
  );
};

export default Quiz;