import React, { useState } from 'react';
import Contact from './Contact';
import Search from './Search';
import Goat from './Goat';
import { TopPerformersTable } from './AllTime';
import Protein from './Protein';
import OldestGoat from './OldestGoat';

import './index.css'; 

const ButtonGrid = ({ setDisplay }) => {
  return (
    <div className="button-grid">
      <button className="hybrid-button" onClick={() => setDisplay('alltime')}>All-time Milk</button>
      <button className="hybrid-button" onClick={() => setDisplay('contact')}>All-time Butterfat</button>
      <button className="hybrid-button" onClick={() => setDisplay('protein')}>All-time Protein</button>
      <button className="hybrid-button" onClick={() => setDisplay('goat')}>Current Top Ten</button>
      <button className="hybrid-button" onClick={() => setDisplay('oldestgoat')}>Oldest Top Ten Goats</button>
      <button className="hybrid-button" onClick={() => setDisplay('search')}>SEARCH</button>
    </div>
  );
};

function HybridView() {
  const [display, setDisplay] = useState(null);

  let DisplayComponent;
  switch (display) {
    case 'search':
      DisplayComponent = Search;
      break;
    case 'goat':
      DisplayComponent = Goat;
      break;
    case 'contact':
      DisplayComponent = Contact;
      break;
    case 'alltime':
      DisplayComponent = TopPerformersTable;
      break;
    case 'protein':
      DisplayComponent = Protein;
      break;
    case 'oldestgoat':
      DisplayComponent = OldestGoat;
      break;
    default:
      DisplayComponent = null;
  }

  return (
    <div>
      <ButtonGrid setDisplay={setDisplay} />
      {DisplayComponent && <DisplayComponent key={display} />}
    </div>
  );
}

export default HybridView;
