import { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

function OldestGoat() {
  const [oldestGoats, setOldestGoats] = useState([]);

  useEffect(() => {
  const fetchOldestGoats = async () => {
    const { data, error } = await supabase
      .from('tryagain')
      .select('goat, owner, breeder, year, milk, rank, AGE')
      .eq('breed', 'Nigerian Dwarf')
      .order('AGE', { ascending: false })
      .limit(10);

    if (error) {
      console.error(error);
    } else {
      const oldestGoats = data
        .filter((goat) => goat.rank.endsWith('M'))
        .map((goat) => {
          const ageString = goat.AGE;
          const ageArray = ageString.split('-');
          const ageInMonths = parseInt(ageArray[0]) * 12 + parseInt(ageArray[1]);
          const ageInYears = Math.floor(ageInMonths / 12);
          const remainingMonths = ageInMonths % 12;
          const ageText = `${ageInYears} years and ${remainingMonths} months old`;

          return {
            name: goat.goat,
            owner: goat.owner,
            breeder: goat.breeder,
            year: goat.year,
            value: goat.value,
            age: ageText,
            milk: goat.milk,
            rank: goat.rank
          };
        });
      setOldestGoats(oldestGoats);
    }
  };

  fetchOldestGoats();
}, []);


 return (
  <div style={{ color: 'charcoal', fontSize: '12px' }}>
    <h3>Oldest Top Ten Milkers</h3>
    {oldestGoats.length > 0 ? (
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Age</th>
            <th>Owner</th>
            <th>Breeder</th>
            <th>Year</th>
            <th>Milk</th>
          </tr>
        </thead>
        <tbody>
          {oldestGoats.map((goat) => (
            <tr key={goat.name}>
              <td>{goat.name}</td>
              <td>{goat.age}</td>
              <td>{goat.owner}</td>
              <td>{goat.breeder}</td>
              <td>{goat.year}</td>
              <td>{goat.milk}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <p>Loading...</p>
    )}
  </div>
);

}

export default OldestGoat;


