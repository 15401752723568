import React, { Component } from "react";

class Home extends Component {
  render() {
    return (
      <div>
      <p><img src="./cover.webp" className="App-logo" alt="logo" /></p>
        <h3><span style={{color: '#0099FF'}}>Creamline Girls: meet the milkers...</span></h3>
        <p>
          Explore the current top producers, all-time bucket busters, and butterfat queens of the breed. Currently the database holds ADGA Nigerian Dwarf breedleaders from 2009-2021. Search by year, owner, breeder, goat. <span style={{color: 'white'}}>Coming soon: AGS breedleaders.</span>
        </p>
        <p>Click 'PedSearch' to search the ancestors database of Nigerian Dwarf pedigrees going back to Pine Cone Valley Black Satin, owned by Kathleen Claps of the legendary Goodwood herd in Texas. Satin was the foundation doe for the breed in America. You would be hard pressed to find a purebred registered ND doe who does not go back to Satin: the little black doe who built the breed.
        </p><p>Click 'Add-a-Goat' to add an animal to the ancestors' database.</p><p>Questions or comments? email <a href="mailto:thenigeriandwarf@gmail.com">thenigeriandwarf@gmail.com</a></p>
        
      </div>
    );
  }
}

export default Home;