import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const PedUsersForm = () => {
  const [formState, setFormState] = useState({
    damid: '',
    sireid: '',
    dob: '',
    dam_name: '',
    sire_name: '',
    name: '',
    sex: '' // No default value for sex dropdown
  });

  const [formMessage, setFormMessage] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { user, error } = await supabase.auth.getUser();

        if (error) {
          throw error;
        }

        console.log('User object:', user);

        if (!user) {
          navigate('/authcomponent');
        }
      } catch (error) {
        console.error('User retrieval error:', error);
        navigate('/authcomponent');
      }
    };

    fetchUser();
  }, []);

  const handleInputChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value
    });
  };

  const handleSearch = async () => {
    const { data, error } = await supabase
      .from('ndpedigree')
      .select('name, PedigreeId')
      .ilike('name', `%${formState.searchName}%`)
      .limit(5);

    if (error) {
      console.error('Search error:', error);
      setSearchResults([]);
    } else {
      console.log('Search results:', data);
      setSearchResults(data);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const user = supabase.auth.getUser();
    if (!user) {
      alert('Please log in to submit data.');
      return;
    }

    // Validation logic
    const dobRegexp = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
    if (formState.dob && !dobRegexp.test(formState.dob)) {
      alert('Please enter dob in the format YYYY-MM-DD');
      return;
    }

    const filledFields = Object.keys(formState).filter(key => Boolean(formState[key]));
    if (filledFields.length < 3 || !formState.name) {
      alert('Please fill at least three fields, one of them must be "name"');
      return;
    }

    const { data, error } = await supabase
      .from('pedusers')
      .insert([{ ...formState, user_id: user.id }]);

    if (error) {
      setFormMessage(`Error: ${error.message}`);
    } else {
      console.log('Submitted data: ', data);
      setFormState({ // Clear the form
        damid: '',
        sireid: '',
        dob: '',
        dam_name: '',
        sire_name: '',
        name: '',
        sex: ''
      });
      setFormMessage('Data submitted successfully!');
    }
  };

  return (
    <div>
      <p>Instructions: Step 1. Use PedSearch to search for the animal - is it already in the database? Great, you're done.</p>

      <p>Step 2. It's very important that any animals added connect to their ancestors in the database. Unconnected or 'orphan' animals add no value. To add an animal to the database, first obtain the PedigreeId of the animal's sire or dam, ideally both.</p>

      <p>Step 3. Search here to obtain the sire and/or dam's PedigreeId. Enter that number very carefully under either SireId or DamId, as appropriate.</p>

      <div>
        <input
          type="text"
          name="searchName"
          value={formState.searchName}
          onChange={handleInputChange}
          placeholder="Search by Name"
          style={{ width: '250px', padding: '5px' }}
        />
        <button onClick={handleSearch}>Search</button>
      </div>

      {searchResults.length > 0 && (
        <div>
          <p>Search Results:</p>
          <ul>
            {searchResults.map(result => (
              <li key={result.PedigreeId}>
                Name: {result.name}, PedigreeId: {result.PedigreeId}
              </li>
            ))}
          </ul>
        </div>
      )}

      <p>Step 4. Fill out the form with at least 4 fields. The more fields, the better. Required fields are: 'name' (the animal's name), sex, and PedigreeId number and name for either the sire or dam. These go in the SireId or DamId boxes, as appropriate. Enter fields very carefully, please check that both spelling and id numbers match your search results exactly. If they do not match your entry will not be processed. </p>

      <p>FAQ</p>

      <p>Do my entries go directly into the database? No. Entries are processed periodically after they are checked to ensure data integrity. Usually updates happen every week or so. </p>

      <p>What if I have a son and grandson I want to enter, can I enter the grandson if I just entered the son and the son has no PedigreeId yet? Yes. Enter 000000 in the grandson's SireId box. DO NOT enter a 000000 for an animal unless you entered the animal. What does that mean? Let's say you entered a son, connecting him to his sire by entering the sire's PedigreeId in the SireId column of the input form. You now want to enter that animal's son or daughter. Add 000000 for the SireId. DO NOT enter 000000 for the DamId, unless you also entered the dam. </p>

      <p>Questions? email thenigeriandwarf@gmail.com</p>


      <p><h3>Got it? Good. Add a goat below.</h3></p>
      <div style={{ margin: '0 auto', maxWidth: '800px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>


    <form onSubmit={handleSubmit} style={{ textAlign: 'left' }}>
        {/* Form fields */}
        <input
          type="text"
          name="damid"
          value={formState.damid}
          onChange={handleInputChange}
          placeholder="Dam ID"
          style={{ width: '250px', padding: '5px' }} // Adjust width and padding
        />
        <input
          type="text"
          name="sireid"
          value={formState.sireid}
          onChange={handleInputChange}
          placeholder="Sire ID"
          style={{ width: '250px', padding: '5px' }} // Adjust width and padding
        />

        <input
          type="text"
          name="dob"
          value={formState.dob}
          onChange={handleInputChange}
          placeholder="Date of Birth (YYYY-MM-DD)"
          style={{ width: '250px', padding: '5px' }} // Adjust width and padding
        />
        <input
          type="text"
          name="dam_name"
          value={formState.dam_name}
          onChange={handleInputChange}
          placeholder="Dam Name"
          style={{ width: '250px', padding: '5px' }} // Adjust width and padding
        />
        <input
          type="text"
          name="sire_name"
          value={formState.sire_name}
          onChange={handleInputChange}
          placeholder="Sire Name"
          style={{ width: '250px', padding: '5px' }} // Adjust width and padding
        />

        <input
          type="text"
          name="name"
          value={formState.name}
          onChange={handleInputChange}
          placeholder="Name"
          style={{ width: '250px', padding: '5px' }} // Adjust width and padding
        />
        <select
          name="sex"
          value={formState.sex}
          onChange={handleInputChange}
          required // Make the field required
          style={{ width: '250px', padding: '5px' }} // Adjust width and padding
        >
          <option value="">Select Gender</option>
          <option value="buck">Buck</option>
          <option value="doe">Doe</option>
        </select>
        <button type="submit">Submit</button>
      </form>
          
  </div>
      <p>{formMessage}</p>
    </div>
  );
};

export default PedUsersForm;
