import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);


const Progeny = () => {
  const [progeny, setProgeny] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchName = searchParams.get('search_name');

  useEffect(() => {
    const fetchProgeny = async () => {
      const { data, error } = await supabase.rpc('find_progeny', { search_name: searchName });

      if (error) {
        console.error('Error calling find_progeny:', error);
        return;
      }

      setProgeny(data);
      setLoading(false);
    };

    fetchProgeny();
  }, [searchName]);

  if (loading) {
    return <h3>Loading...</h3>;
  }

  return (
    <>
    <h3>Progeny of {searchName}</h3>
 <div style={{ display: 'flex', justifyContent: 'space-around', margin: '10px 0' }}>
    <a 
      href={`/Siblings?search_name=${searchName}`} 
      style={{ fontSize: '12px', padding: '10px' }}>
      Siblings of {searchName}
    </a>   
    <a 
      href={`/Ped?search_name=${searchName}`} 
      style={{ fontSize: '12px', padding: '10px' }}>
      Pedigree of {searchName}
    </a>
  </div>
    {progeny.length === 0 ?
      <p>No progeny found</p> :
      <table className="results-table">
        <thead>
          <tr>
            <th>Goat Name</th>
            <th>Dam Name</th>
            <th>Sire Name</th>
          </tr>
        </thead>
        <tbody>
          {progeny.map((child) => (
            <tr key={child.PedigreeId}>
              <td><Link to={`/Ped?search_name=${child.animal_name}`}>{child.animal_name}</Link></td>
              <td>{child.dam_name}</td>
              <td>{child.sire_name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    }
    </>
  );
};

export default Progeny;
