import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { useNavigate, useLocation } from "react-router-dom"; 
import './results-table2.css'; 
import { Link } from 'react-router-dom';


const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);


const Ped = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const searchParams = new URLSearchParams(location.search);
  const initialSearchName = searchParams.get('search_name') || '';
  const [searchTerm, setSearchTerm] = useState(initialSearchName); // Initialize searchTerm with search_name query parameter
  const [searchResults, setSearchResults] = useState([]);
  const [result, setResult] = useState(null);
  const [clickedName, setClickedName] = useState('');


  const handleSiblingsClick = () => {
    navigate(`/Siblings?search_name=${clickedName}`);
  };

  const handleProgenyClick = () => {
    navigate(`/Progeny?search_name=${clickedName}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (searchTerm.trim() === '') {
        setSearchResults([]);
        return;
      }

      const { data, error } = await supabase
        .from('ndpedigree')
        .select('name')
        .ilike('name', `%${searchTerm}%`)
        .limit(10);

      if (error) {
        console.error('Error fetching search results:', error);
        return;
      }

      setSearchResults(data);
    };

    fetchData();
  }, [searchTerm]);

  // If there's a search name present in the query parameters, fetch its ancestors directly
  useEffect(() => {
    if (initialSearchName) {
      handleNameClick(initialSearchName);
    }
  }, [initialSearchName]);

  const handleSearch = async () => {
    if (searchTerm.trim() === '') {
      setSearchResults([]);
      return;
    }

    const { data, error } = await supabase
      .from('ndpedigree')
      .select('name')
      .ilike('name', `%${searchTerm}%`)
      .limit(10);

    if (error) {
      console.error('Error fetching search results:', error);
      return;
    }

    setSearchResults(data);
  };

  const handleNameClick = async (name) => {
    console.log('Clicked name:', name);

    const { data, error } = await supabase.rpc('find_ancestors', { search_name: name });

    console.log('RPC response:', { data, error });

    if (error) {
      console.error('Error calling find_ancestors:', error);
      return;
    }

    setResult(data[0]); // Set the result to the first element of the data array
    setClickedName(name);
};

  return (
    <div>    <p style={{marginBottom: '20px', fontSize: '14px', color: '#333'}}>
      Search the nDwarf Ancestors Database for foundational Nigerian Dwarf pedigrees.
    </p> 
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button onClick={handleSearch}>Search</button>

      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {/* Apply the color changes only to the list of clickable names */}
        {searchResults.map((result) => (
          <li key={result.name}>
            <span
              className={`clickable-text ${clickedName === result.name ? 'clicked' : ''}`}
              onClick={() => handleNameClick(result.name)}
            >
              {result.name}
            </span>
          </li>
        ))}
      </ul>

      {result && ( // Render the table only if result is available
        
        <table className="results-table2 custom-table">
          <thead>
            <tr>
              <th>NAME {result.animal_name}:</th>
              <th></th>
              <th>
                <a href={`/siblings?search_name=${result.animal_name}`} onClick={handleSiblingsClick}>
                  SIBLINGS
                </a>
              </th>
              <th>
                <a href={`/progeny?search_name=${result.animal_name}`} onClick={handleProgenyClick}>
                  PROGENY
                </a>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>SSSS <a href={`#${result.ssss_name}`} onClick={() => handleNameClick(result.ssss_name)}>
                      {result.ssss_name}
                    </a></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td colSpan="2">SSS <a href={`#${result.sss_name}`} onClick={() => handleNameClick(result.sss_name)}>
                      {result.sss_name}
                    </a></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>SSSD <a href={`#${result.sssd_name}`} onClick={() => handleNameClick(result.sssd_name)}>
                      {result.sssd_name}
                    </a></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td colSpan="2">SS <a href={`#${result.ss_name}`} onClick={() => handleNameClick(result.ss_name)}>
                      {result.ss_name}
                    </a></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>SSDS <a href={`#${result.ssds_name}`} onClick={() => handleNameClick(result.ssds_name)}>
                      {result.ssds_name}
                    </a></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td colSpan="2">SSD <a href={`#${result.ssd_name}`} onClick={() => handleNameClick(result.ssd_name)}>
                      {result.ssd_name}
                    </a></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>SSDD <a href={`#${result.ssdd_name}`} onClick={() => handleNameClick(result.ssdd_name)}>
                      {result.ssdd_name}
                    </a></td>
    </tr>
    <tr>
      <td></td>
      <td>SIRE <a href={`#${result.sire_name}`} onClick={() => handleNameClick(result.sire_name)}>
                      {result.sire_name}
                    </a>
</td>
      <td></td>
      <td></td>
      <td></td>
    </tr>

    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>SDSS <a href={`#${result.sdss_name}`} onClick={() => handleNameClick(result.sdss_name)}>
                      {result.sdss_name}
                    </a></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td colSpan="2">SDS <a href={`#${result.sds_name}`} onClick={() => handleNameClick(result.sds_name)}>
                      {result.sds_name}
                    </a></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>SDSD <a href={`#${result.sdsd_name}`} onClick={() => handleNameClick(result.sdsd_name)}>
                      {result.sdsd_name}
                    </a></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td colSpan="2">SD <a href={`#${result.sd_name}`} onClick={() => handleNameClick(result.sd_name)}>
                      {result.sd_name}
                    </a></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>SDDS <a href={`#${result.sdds_name}`} onClick={() => handleNameClick(result.sdds_name)}>
                      {result.sdds_name}
                    </a></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td colSpan="2">SDD <a href={`#${result.sdd_name}`} onClick={() => handleNameClick(result.sdd_name)}>
                      {result.sdd_name}
                    </a></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>SDDD <a href={`#${result.sddd_name}`} onClick={() => handleNameClick(result.sddd_name)}>
                      {result.sddd_name}
                    </a></td>
    </tr>
    <tr>
      <td colSpan="2">NAME {result.animal_name}:</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>DSSS <a href={`#${result.dsss_name}`} onClick={() => handleNameClick(result.dsss_name)}>
                      {result.dsss_name}
                    </a></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td colSpan="2">DSS <a href={`#${result.dss_name}`} onClick={() => handleNameClick(result.dss_name)}>
                      {result.dss_name}
                    </a></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>DSSD <a href={`#${result.dssd_name}`} onClick={() => handleNameClick(result.dssd_name)}>
                      {result.dssd_name}
                    </a></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>DS <a href={`#${result.ds_name}`} onClick={() => handleNameClick(result.ds_name)}>
                      {result.ds_name}
                    </a></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>DSDS <a href={`#${result.dsds_name}`} onClick={() => handleNameClick(result.dsds_name)}>
                      {result.dsds_name}
                    </a></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td colSpan="2">DSD <a href={`#${result.dsd_name}`} onClick={() => handleNameClick(result.dsd_name)}>
                      {result.dsd_name}
                    </a></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>DSDD <a href={`#${result.dsdd_name}`} onClick={() => handleNameClick(result.dsdd_name)}>
                      {result.dsdd_name}
                    </a></td>
    </tr>
    <tr>
      <td></td>
      <td>DAM <a href={`#${result.dam_name}`} onClick={() => handleNameClick(result.dam_name)}>
                      {result.dam_name}
                    </a></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>DDSS <a href={`#${result.ddss_name}`} onClick={() => handleNameClick(result.ddss_name)}>
                      {result.ddss_name}
                    </a></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td colSpan="2">DDS <a href={`#${result.dds_name}`} onClick={() => handleNameClick(result.dds_name)}>
                      {result.dds_name}
                    </a></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>DDSD <a href={`#${result.ddsd_name}`} onClick={() => handleNameClick(result.ddsd_name)}>
                      {result.ddsd_name}
                    </a></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td colSpan="2">DD <a href={`#${result.dd_name}`} onClick={() => handleNameClick(result.dd_name)}>
                      {result.dd_name}
                    </a></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>DDDS <a href={`#${result.ddds_name}`} onClick={() => handleNameClick(result.ddds_name)}>
                      {result.ddds_name}
                    </a></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td colSpan="2">DDD <a href={`#${result.ddd_name}`} onClick={() => handleNameClick(result.ddd_name)}>
                      {result.ddd_name}
                    </a></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>DDDD <a href={`#${result.dddd_name}`} onClick={() => handleNameClick(result.dddd_name)}>
                      {result.dddd_name}
                    </a></td>
    </tr>
  </tbody>
</table>

      )}
        
    </div>
  );
};

export default Ped;
