import { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);


function Goat() {
  const [nigerianDwarfGoats, setNigerianDwarfGoats] = useState([]);

  useEffect(() => {
    const fetchNigerianDwarfGoats = async () => {
      const { data, error } = await supabase
        .from('tryagain')
        .select('goat, milk, year, breeder, breed, rank')
        .eq('breed', 'Nigerian Dwarf')
        .like('rank', '%M')
        .not('rank', 'like', '%B')
        .not('rank', 'like', '%P')
        .eq('year', 2021)
        .order('milk', { ascending: false })
        .limit(15);
        
      if (error) {
        console.error(error);
      } else {
        setNigerianDwarfGoats(data);
      }
    }
    
    fetchNigerianDwarfGoats();
  }, []);

return (
<div>
<h2>The Current Milk Queens</h2>
<table className="results-table">
<thead>
<tr>
<th>Rank</th>
<th>Name</th>
<th>Milk</th>
<th>Year</th>
<th>Breeder</th>
</tr>
</thead>
<tbody>
{nigerianDwarfGoats.map((goat, index) => (
<tr key={goat.goat} style={{color: index === 0 ? 'red' : 'black'}}>
<td>{index + 1}</td>
<td>{goat.goat}</td>
<td>{goat.milk}</td>
<td>{goat.year}</td>
<td>{goat.breeder}</td>
</tr>
))}
</tbody>
</table>
</div>
);
}

export default Goat;

