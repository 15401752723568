import React, { useState, useEffect, useCallback } from 'react';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);


const Contact = () => {
  const [goats, setGoats] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchGoats = useCallback(async () => {
    // Fetch goats when the component mounts or when the user re-enters the page
    console.log('fetchGoats called');
    const { data, error } = await supabase
      .from('tryagain')
      .select('goat, milk, P, year, breeder')
      .gt('P', 60) // Only return goats with an F value of 100 or higher
      .ilike('rank', '%P') // Only return goats with a rank value that ends with P
      .order('P', { ascending: false }); // Sort goats in descending order of P value

    if (error) {
      console.log(error);
    } else {
      console.log(data);
      setGoats(data);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchGoats();
  }, [fetchGoats]);

  useEffect(() => {
    console.log('goats:', goats);
  }, [goats]);

  useEffect(() => {
    console.log('loading:', loading);
  }, [loading]);

  useEffect(() => {
    console.log('rendered');
  });

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h2>All-time Protein:</h2>
      <p><i>Components, girls. That's what makes the world go 'round. The ND Protein Power Rangers.</i></p>
      <table className="results-table">
        <thead>
          <tr>
            <th>Goat</th>
            <th>Milk (lbs)</th>
            <th style={{backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Protein</th>
            <th>Year</th>
            <th>Breeder</th>
          </tr>
        </thead>
        <tbody>
          {goats.map((goat, index) => (
            <tr key={`${goat.goat}-${index}`}>
              <td>{goat.goat}</td>
              <td>{goat.milk}</td>
              <td style={{backgroundColor: '#FFF', color: 'red', fontWeight: 'bold'}}>{goat.P}</td>
              <td>{goat.year}</td>
              <td>{goat.breeder}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Contact;



