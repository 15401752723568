import React, { useState, useEffect, useCallback } from 'react';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const Contact = () => {
  const [goats, setGoats] = useState([]);
  const [sortedGoats, setSortedGoats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState('F');

  const fetchGoats = useCallback(async () => {
    console.log('fetchGoats called');
    const { data, error } = await supabase
      .from('tryagain')
      .select('goat, milk, F, year, breeder')
      .gt('F', 99)
      .ilike('rank', '%B')
      .order('F', { ascending: false });

    if (error) {
      console.log(error);
    } else {
      const updatedData = data.map(goat => ({
        ...goat,
        butterfatPercentage: (goat.F / goat.milk) * 100,
      }));
      console.log(updatedData);
      setGoats(updatedData);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchGoats();
  }, [fetchGoats]);

  useEffect(() => {
    const sorted = [...goats].sort((a, b) => b[sortBy] - a[sortBy]);
    setSortedGoats(sorted);
  }, [sortBy, goats]);

  if (loading) {
    return <p>Loading...</p>;
  }

  const selectedStyle = {
    backgroundColor: '#FFF',
    color: 'red',
    fontWeight: 'bold',
    cursor: 'pointer',
  };

  const defaultStyle = {
    cursor: 'pointer',
  };

  return (
    <div>
      <h2>All-time butterfat:</h2>
      <p><i>It's all about the butterfat, baby. Because the rest is just water. Nigerians milking 100+ pounds of butterfat. Toggle to sort by percentage.</i></p>
      <table className="results-table">
        <thead>
          <tr>
            <th>Goat</th>
            <th>Milk (lbs)</th>
            <th 
              style={sortBy === 'F' ? selectedStyle : defaultStyle}
              onClick={() => setSortBy('F')}>
              Butterfat (lbs)
            </th>
            <th 
              style={sortBy === 'butterfatPercentage' ? selectedStyle : defaultStyle}
              onClick={() => setSortBy('butterfatPercentage')}>
              Butterfat (%) {/* Add onClick to column header */}
            </th> 
            <th>Year</th>
            <th>Breeder</th>
          </tr>
        </thead>
        <tbody>
          {sortedGoats.map((goat, index) => (
            <tr key={`${goat.goat}-${index}`}>
              <td>{goat.goat}</td>
              <td>{goat.milk}</td>
              <td style={sortBy === 'F' ? selectedStyle : {}}>{goat.F}</td>
              <td style={sortBy === 'butterfatPercentage' ? selectedStyle : {}}>{goat.butterfatPercentage.toFixed(2)}%</td>
              <td>{goat.year}</td>
              <td>{goat.breeder}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Contact;
