import { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import memoize from 'lodash/memoize';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);


// We don't need to import memoize if we're using lodash/memoize directly
// Also, since we're not using this function outside of the component, there's no need to export it
const cachedFetch = memoize(async (table, columns, order, limit) => {
  const { data, error } = await supabase
    .from(table)
    .select(columns)
    .order(order, { ascending: false })
    .limit(limit);

  if (error) {
    throw new Error(error);
  }

  return data;
});

    
export function TopPerformersTable() {
  const [topPerformers, setTopPerformers] = useState([]);

  useEffect(() => {
    async function fetchTopPerformers() {
      try {
        // Fetch the data from the supabase API
        const data = await cachedFetch(
          'tryagain',
          'id, goat, year, milk, breeder, rank',
          'milk',
          308
        );

        // Filter the data to only include goats whose rank ends with 'M'
        const filteredData = data.filter(
          (record) => record.rank.endsWith('M')
        );

        // Use a Map to keep track of which performers have already appeared in the top 10
        const performerYears = new Map();
        const performers = [];

        // Iterate through each record in the filtered data
        filteredData.forEach((record) => {
          const { id, goat, year, milk, breeder } = record;
          
          if (performerYears.has(id)) {
            // If the performer has already appeared in the top 10 for a previous year,
            // add the year to their existing entry in the performers array
            performers[performerYears.get(id)].year.push(year);
          } else {
            // Otherwise, add the performer to the performers array
            performers.push({ id, goat, milk, year: [year], breeder });
            performerYears.set(id, performers.length - 1);
          }
        });

        // Sort the years for each performer in descending numeric order
        const sortedPerformers = performers.map((performer) => ({
          ...performer,
          year: performer.year.sort((a, b) => b - a)
        }));

        // Remove unwanted trailing commas from the years string
        const filteredPerformers = sortedPerformers.map((performer) => ({
          ...performer,
          year: performer.year.join(', ')
        }));

        setTopPerformers(filteredPerformers);
      } catch (error) {
        console.error(error);
      }
    }

    fetchTopPerformers();
  }, []);

  return (
    <div>
      <h2>The All-time Nigie Milkers</h2>
      <p><i>Tiny titans of the dairy world. The all-time ND bucket busters with 1200+ milk records.</i></p>
      <table className="results-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th>Milk</th>
            <th>Breeder</th>
            <th>Years in the Top Ten</th>
          </tr>
        </thead>
        <tbody>
          {topPerformers.map((performer, index) => (
            <tr key={performer.id} style={{fontWeight: index === 0 ? "bold" : "normal", color: index === 0 ? "white" : "inherit", backgroundColor: index === 0 ? "red" : "inherit"}}>
              <td>{index + 1}</td>
              <td>{performer.goat}</td>
              <td>{performer.milk} lbs</td>
              <td>{performer.breeder}</td>
              <td>{performer.year}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
