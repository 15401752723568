import { createClient } from '@supabase/supabase-js';
import { useState } from 'react';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);


const Search = () => {
  const [goat, setGoat] = useState('');
  const [breeder, setBreeder] = useState('');
  const [owner, setOwner] = useState('');
  const [year, setYear] = useState('');
  const [results, setResults] = useState([]);

const handleSearch = async (event) => {
  event.preventDefault();

   // Only query the database if there is a search term entered
    if (!goat && !breeder && !owner && !year) {
      return;
    }

  const query = supabase
    .from('tryagain')
    .select('goat, breeder, owner, year, milk, rank')
    .ilike('goat', `%${goat}%`)
    .ilike('breeder', `%${breeder}%`)
    .ilike('owner', `%${owner}%`);

  if (year) {
    query.eq('year', Number(year));
  }

  const { data, error } = await query;

  if (error) {
    console.log(error);
  } else {
    const filteredResults = data && data.filter(
      (result) =>
        result.goat.toLowerCase().includes(goat.toLowerCase()) &&
        result.breeder.toLowerCase().includes(breeder.toLowerCase()) &&
        result.owner.toLowerCase().includes(owner.toLowerCase()) &&
        result.rank.includes('M') &&
        !result.rank.trim().endsWith('B') &&
        !result.rank.trim().endsWith('P')
    );

    if (filteredResults && filteredResults.length > 0) {
      setResults(filteredResults);
    } else {
      setResults([{ goat: 'No results, please try again!' }]);
    }
  }
};



return (
  <div className="container">
    <form onSubmit={handleSearch}>
      <div className="input-container">
        <label htmlFor="goat-input" style={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red'}}>SEARCH THE TOP TEN BY:</label>

        <input
          type="text"
          id="goat-input"
          placeholder="Doe's name"
          value={goat}
          onChange={(event) => setGoat(event.target.value)}
        />
      </div>
      <br />
      <div className="input-container">
        <label htmlFor="breeder-input"></label>
        <input
          type="text"
          id="breeder-input"
          placeholder="Breeder's name"
          value={breeder}
          onChange={(event) => setBreeder(event.target.value)}
        />
      </div>
      <br />
      <div className="input-container">
        <label htmlFor="owner-input"></label>
        <input
          type="text"
          id="owner-input"
          placeholder="Owner's name"
          value={owner}
          onChange={(event) => setOwner(event.target.value)}
        />
      </div>
      <br />
      <label htmlFor="year-input"></label>
<input
  type="text"
  id="year-input"
  placeholder="Year - YYYY"
  value={year}
  onChange={(event) => setYear(event.target.value)}
  pattern="[0-9]*"
  
/>
      <br />
      <button type="submit">Search</button>
    </form>
    <table className="results-table">
      <thead>
        <tr>
          <th>Doe</th>
          <th>Breeder</th>
          <th>Owner</th>
          <th>Year</th>
          <th>Milk</th>
          <th>Rank</th>
        </tr>
      </thead>
      <tbody>
        {results.map((result) => (
          <tr key={result.goat}>
            <td>{result.goat}</td>
            <td>{result.breeder}</td>
            <td>{result.owner}</td>
            <td>{result.year}</td>
            <td>{result.milk}</td>
            <td>{result.rank}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

  };

export default Search;